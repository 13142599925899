import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ReactNode } from 'react'
import AnimateText from './AnimateText'
import { primaryButtonCss, primaryButtonCssYachting } from './Button'
import {
  isSiteAmels,
  isSiteXplorer,
  isSiteYachtSupport,
  isSiteYachting,
} from '../themes'
import LinkButton from './LinkButton'
import { overlineMedium } from '../themes/xplorer/text'
import { currentSite, Site } from '../sites'

const Content = styled('div')(({ theme }) => [
  css`
    max-width: 738px;
    text-align: center;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      margin: 0 auto;
    }
  `,
  isSiteXplorer(theme) &&
    css`
      max-width: 862px;
    `,
  isSiteYachtSupport(theme) &&
    css`
      max-width: 906px;
    `,
])
const Overline = styled('div')(({ theme }) => [
  css`
    ${!isSiteYachtSupport(theme) && overlineMedium(theme)}
    color: ${theme.colors.xplorer.secondaryXPBronze};
    text-transform: uppercase;
    margin-bottom: ${theme.spacing.x2}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      margin-bottom: ${theme.spacing.x3}px;
    }
  `,
  isSiteYachtSupport(theme) &&
    css`
      ${theme.text.overLineMedium(theme)}
      color: ${theme.colors.yachtSupport.secondaryYSCoralGreen};
    `,
])
const Title = styled('h1')(({ theme }) => [
  css`
    ${theme.text.heading1(theme)}
    margin-bottom: ${theme.spacing.x2}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      margin-bottom: ${theme.spacing.x5}px;
    }
  `,
  isSiteAmels(theme) &&
    css`
      color: ${theme.colors.amels.bayGold};
    `,
  isSiteXplorer(theme) &&
    css`
      ${theme.text.heading2(theme)}
      color: ${theme.colors.xplorer.primaryXPDarkOceanGreen};
      margin-bottom: ${theme.spacing.x4}px;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        margin-bottom: ${theme.spacing.x5}px;
      }
    `,
  isSiteYachtSupport(theme) &&
    css`
      ${theme.text.heading2(theme)}
      text-transform: none;
      margin: ${theme.spacing.x2}px 0 ${theme.spacing.x4}px;
      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        margin: ${theme.spacing.x4}px 0;
      }
    `,
])
const Text = styled('div')(({ theme }) => [
  css`
    ${theme.text.body(theme)}
    margin-bottom: ${theme.spacing.x2}px;
    color: ${theme.colors.amels.bodyText};

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      margin-bottom: ${theme.spacing.x5}px;
    }
  `,
  isSiteXplorer(theme) &&
    css`
      color: ${theme.colors.xplorer.primaryXPLavaBlack};
      margin-bottom: ${theme.spacing.x4}px;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        margin-bottom: ${theme.spacing.x5}px;
      }
    `,
  isSiteYachtSupport(theme) &&
    css`
      color: ${theme.colors.yachtSupport.secondaryYSWarmGrey};
      margin-bottom: ${theme.spacing.x4}px;
      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        margin-top: ${theme.spacing.x5}px;
        margin-bottom: ${theme.spacing.x6}px;
      }
    `,
])
const LinkComponent = styled(LinkButton)(({ theme }) => [
  css`
    text-decoration: none;
  `,
  isSiteAmels(theme) &&
    css`
      ${primaryButtonCss(theme)}
    `,
  isSiteYachting(theme) &&
    css`
      ${primaryButtonCssYachting(theme)}
    `,
  isSiteXplorer(theme) &&
    css`
      width: auto;
      position: relative;
      border: 2px solid ${theme.colors.xplorer.primaryXPLavaBlack};
      color: ${theme.colors.xplorer.primaryXPLavaBlack};
      padding-top: 10px;
      padding-bottom: 10px;

      :hover {
        background: ${theme.colors.xplorer.primaryXPLavaBlack};
        color: ${theme.colors.xplorer.white};
      }
    `,
])

interface Props {
  overline?: ReactNode
  title: ReactNode
  text: ReactNode
  linkText?: ReactNode
  href?: string
}

const Error = ({ overline, title, text, linkText, href }: Props) => (
  <Content data-testid="error">
    {currentSite === Site.Xplorer ||
      (currentSite === Site.YachtSupport && <Overline>{overline}</Overline>)}
    <Title>
      <AnimateText>{title}</AnimateText>
    </Title>
    <Text>
      <AnimateText delay={200}>{text}</AnimateText>
    </Text>
    {href && linkText && (
      <LinkComponent href={href} data-testid="errorLink" variant="secondary">
        {linkText}
      </LinkComponent>
    )}
  </Content>
)

export default Error
