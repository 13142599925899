import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentProps, ReactNode } from 'react'

import Error from '../components/Error'
import NavigationBar from '../components/NavigationBar/NavigationBar'
import { isSiteYachtSupport } from '../themes'

const Container = styled.div(({ theme }) => [
  css`
    display: flex;
    align-items: center;
    padding: ${theme.spacing.x10}px ${theme.spacing.x4}px;
    min-height: 100vh;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      text-align: center;
      padding: ${theme.spacing.x20}px ${theme.spacing.x5}px
        ${theme.spacing.x20}px;
    }
  `,
  isSiteYachtSupport(theme) &&
    css`
      padding: ${theme.spacing.x10}px ${theme.spacing.x5}px;
    `,
])

interface Props
  extends Omit<ComponentProps<typeof Container>, 'children' | 'title'> {
  overline?: ReactNode
  title: ReactNode
  text: ReactNode
  linkText?: ReactNode
  href?: string
}

const ErrorBlok = ({
  overline,
  title,
  text,
  linkText = 'Go to homepage',
  href = '/',
  ...others
}: Props) => (
  <Container {...others}>
    <NavigationBar variant="dark" menuItems={[]} />

    <Error
      overline={overline}
      title={title}
      text={text}
      linkText={linkText}
      href={href}
    />
  </Container>
)

export default ErrorBlok
