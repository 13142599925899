import ErrorBlok from '../bloks/ErrorBlok'

const ErrorPage404 = () => {
  return (
    <ErrorBlok
      overline="information"
      title="Something went wrong here..."
      text="We can’t find the page you’re looking for. Head back to the homepage."
    />
  )
}

export default ErrorPage404
